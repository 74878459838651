import type { ComputedRef } from "vue";
import { toValue } from "vue";
import type { FaqItem } from "~/types/strapi/api/faq-item/content-types/faq-item/faq-item";

export function useFaqPage(faqItems: FaqItem[] | ComputedRef<FaqItem[]>) {
  const items = toValue(faqItems);
  if (items.length === 0) {
    return;
  }

  return useSchemaOrg([
    defineWebPage({
      "@type": "FAQPage",
    }),
    ...items.map((item) => {
      return defineQuestion({
        answer: item.attributes.answer,
        inLanguage: "de",
        name: item.attributes.question,
      });
    }),
  ]);
}
